import showModal from '@/utils/show_modal';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default function({
  trigger,
  vueComponent,
  cssClass,
  isCloseOnSubmit = false,
  isDestroyOnHide = false,
  props = null
}) {
  // console.log('makeVueModal', {
  //   trigger,
  //   vueComponent,
  //   cssClass,
  //   isCloseOnSubmit,
  //   isDestroyOnHide,
  //   props
  // });
  const $trigger = $(trigger);
  const $modal = $(`
    <div class="b-modal${cssClass ? ' ' + cssClass : ''}">
      <div class="modal-shade"></div>
    </div>
  `);
  let vueApp = null;

  $modal.appendTo(document.body);

  disablePageScroll();
  const modal = showModal({ $modal, $trigger });
  const extendedModal = {
    ...modal,
    destroy() {
      enablePageScroll();
      modal.destroy();
      $modal.remove();

      if (vueApp) {
        vueApp.unmount();
      }
    }
  };

  renderVueApp({
    $modal,
    modal,
    vueComponent,
    props: props || $trigger.data(),
    isCloseOnSubmit
  }).then(app => vueApp = app);

  $modal.on('modal:hide', () => enablePageScroll());
  if (isDestroyOnHide) { $modal.one('modal:hide', extendedModal.destroy); }

  return extendedModal;
}

async function renderVueApp({ $modal, modal, vueComponent, props, isCloseOnSubmit }) {
  const $modalInner = $(`
    <div class="modal-inner">
      <button class="modal-close"></button>
      <div class="v4" />
    </div>
  `);
  const $node = $modalInner.find('.v4');

  const { createApp, h } = await import(/* webpackChunkName: "vue" */ 'vue');
  const eventHandlers = { onModalClose: modal.hide };
  if (isCloseOnSubmit) {
    eventHandlers.onModalSubmit = modal.hide;
  }

  const vueApp = createApp({
    setup: () => () => h(vueComponent, { ...props, ...eventHandlers })
  });
  vueApp.mount($node[0]);

  $modal.append($modalInner);

  $modal.find('.modal-shade').on('click', modal.hide);
  $modal.find('.modal-close').on('click', modal.hide);

  return vueApp;
}
